#outer {
  display: block;
}

#cub-monitor {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 896px;
  height: 648px;
}

#cub-monitor-pic {
  position: absolute;
}

#nec-tv {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 1023px;
  height: 668px;
}

#nec-tv-pic {
  position: absolute;
}

.sidebar {
  position: absolute;
  z-index: -2;
}

.sidebar.right {
  right: -205px;
  top: 0;
}

.sidebar.left {
  left: -205px;
  top: 0;
}

.sidebar.bottom {
  bottom: -100px;
}

#audio-warning {
  position: absolute;
  width: 100%;
  z-index: 1;
}

#screen {
  position: absolute;
  z-index: -1;
  top: 8px;
  left: 0;
  display: block;
  width: 896px;
  height: 600px;
}

#nec-tv-screen {
  position: absolute;
  z-index: -1;
  top: 32px;
  left: 0;
  display: block;
  width: 830px;
  height: 622px;
}

#debug {
  background-color: #333333;
  border: 1px solid #555555;
  margin: 2px;
  padding: 2px;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 8pt;
  font-family: monospace;
}

#hardware_debug {
  background-color: #333333;
  border: 1px solid #555555;
  margin: 2px;
  padding: 2px;
  position: fixed;
  bottom: 80px;
  left: 0;
  font-size: 8pt;
  font-family: monospace;
}

#registers {
  float: right;
  text-align: right;
  width: 90px;
}

#crtc_debug {
  background-color: #333333;
  border: 1px solid #555555;
  margin: 2px;
  padding: 2px;
  position: fixed;
  top: 80px;
  right: 0;
  font-size: 8pt;
  font-family: monospace;
}

h6.dbg {
  text-align: center;
}

.via_regs {
  display: inline-block;
}

.crtc_regs {
  display: inline-block;
}

.crtc_state {
  display: inline-block;
}

span.accesskey {
  text-decoration: underline;
}

.debug-container {
  float: left;
  width: 400px;
}

.goto-addr {
  max-width: 6em;
}

#leds {
  box-shadow: 3px 3px 5px #000;
  border-radius: 6px;
  border: 1px solid #555555;
  background-color: #181818;
  margin: 2px;
  padding: 2px;
  font-size: 8pt;
  bottom: 0;
  left: 0;
  position: fixed;
}

/* hide the LED headers when the LEDs would likely cover the screen */
@media screen and (max-width: 1200px) and (max-height: 700px) {
  #leds thead {
    display: none;
  }

  div.led {
    vertical-align: middle;
  }
}

#leds td,
th {
  padding: 2px;
  text-align: center;
}

#cas-controls {
  box-shadow: 3px 3px 5px #000;
  border-radius: 6px;
  border: 1px solid #555555;
  background-color: #ffffff;
  margin: 2px;
  padding: 2px;
  font-size: 8pt;
  bottom: 0;
  right: 0;
  position: fixed;
}

#sth-list {
  height: 300px;
  overflow: auto;
}

div.filter {
  margin-top: 8px;
}

div.led {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.red.led {
  background-image: url(images/red-off-16.png);
}

.red.led.on {
  background-image: url(images/red-on-16.png);
}

.yellow.led {
  background-image: url(images/yellow-off-16.png);
}

.yellow.led.on {
  background-image: url(images/yellow-on-16.png);
}

.cas.button {
  color: #dddddd;
  border: 1px solid #666666;
  border-radius: 2px;
  padding: 2px;
  background-color: #000;
  box-shadow: 2px 2px 1px #888888;
  display: inline-block;
  min-width: 2em;
  min-height: 2em;
}

.cas.counter {
  height: auto;
  border: none;
  background-color: #555555;
  max-width: 290px;
  vertical-align: center;
  padding-top: 12px;
  padding-left: 10px;
  align-content: center;
}
.cas.counter.num_tiles {
  width: 30px;
  height: 30px;
  background-color: #888888;
  color: #ffffff;
  font-size: 22px;
  text-align: center;
  line-height: 20px;
  padding: 3px;
  margin: 1.5px;
  font-family: verdana;
  vertical-align: center;
}

#pages {
  margin-top: 10px;
}

.key {
  color: #dddddd;
  border: 1px solid #666666;
  border-radius: 2px;
  padding: 2px;
  background-color: #000;
  box-shadow: 2px 2px 1px #888888;
  display: inline-block;
  min-width: 2em;
}

.hidden-unless-db-enabled {
  display: none;
}

.key.dbl {
  font-size: 10px;
}

.key.function {
  background-color: #911;
}

table.keymap th {
  width: 5em;
}

table.keymap td {
  text-align: center;
  padding: 8px;
  border-spacing: 4px;
}

div.modal-body > div:not(:first-child) {
  padding-top: 1em;
}

.dis_addr {
  display: inline-block;
  width: 8em;
  color: #d0d0d0;
}

.instr_bytes {
  display: inline-block;
  width: 6em;
  color: #80d0d0;
}

.instr_asc {
  display: inline-block;
  width: 3em;
  color: #60f060;
}

.mem_bytes {
  display: inline-block;
  width: 18em;
  color: #80d0d0;
}

.mem_asc {
  display: inline-block;
  width: 9em;
  color: #60f060;
}

.instr_mem_ref,
.instr_instr_ref {
  cursor: pointer;
  color: #66f;
}

.instr_mem_ref:hover,
.instr_instr_ref:hover {
  text-decoration: underline;
}

.addr:hover {
  background-color: #330000;
}

.addr:focus {
  background-color: #ff0000;
}

.label {
  color: white;
  font-family: arial, tahoma, sans-serif;
  font-weight: bold;
}

.addr > .editable {
  width: 4em;
  color: black;
  border: 1px;
}

.opcode {
  font-weight: bold;
}

.register {
  color: #88ee88;
}

.current {
  background-color: #227777;
}

.highlight {
  border: 1px solid #444;
}

.value {
  color: #eee;
}
.changed {
  background-color: #772222;
}

.template {
  display: none;
}

.initially-hidden {
  display: none;
}

.bp_gutter {
  width: 8px;
  display: inline-block;
  height: 8px;
  border-radius: 4px;
  margin-right: 2px;
}

.bp_gutter.active {
  background-color: #e02020;
}

#error-dialog div.error {
  background-color: #440000;
  margin-top: 5px;
}

small {
  color: #999;
}
